import { GetServerSidePropsContext } from 'next';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

import { PlatformType } from '@invideoio/web-shared/constants/common';
import { PageType } from '@invideoio/web-shared/types/Analytics';
import { AnalyticsUtils } from '@invideoio/web-shared/utils/analytics/AnalyticsUtils';
import { registerAndSendPageViewEvent } from '@invideoio/web-shared/utils/analytics/Register';

import AppLayout from '../layouts/AppLayout';

import {
  AmplitudeEvents,
  sendAmplitudeAnalyticEvent,
  setCommonAnalyticsProperties,
} from '@utils/analytics';
import useLazyload from '../hooks/useLazyload';
import { DispatchContext, StateContext } from '../store/store';

import HomepageSEO from '@components/common/SEO/HomepageSEO';
import HeaderWithCTA from '@components/header-with-cta';
import { RELATIVE_PATH } from '@constants/relative-path';
import useSharedState from '@invideoio/web-shared/hooks/useSharedState';
import { UserType } from '@invideoio/web-shared/types/Auth';
import { Referrer } from '@invideoio/web-shared/utils/utils.constant';
import HomePageV2 from '@layouts/HomePageV2';
import { generateRedirectionURLwithHost } from '@utils/common.utils';
import { setCacheControlHeaders } from '@utils/server.utils';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import qs from 'query-string';
import { openTeamSelectionModal } from 'src/store/actions';
import { BrowserProps, HomepageResponseType } from '../types';
import { fetchMasterJSON, fetchTemplates, getHomepageV2 } from './../lib/API';

const FixedBanner = dynamic(
  () =>
    import(
      /* webpackChunkName: "FixedBanner" */
      '@invideoio/web-shared/components/FixedBanner'
    ),
);

const Banner = dynamic(
  () =>
    import(
      /* webpackChunkName: "Banner" */
      '@components/banner'
    ),
);
interface HomeProps extends BrowserProps {
  homepageState: HomepageResponseType;
}

export function getReviewRedirectURL(
  user: UserType | null,
  teamToken: string,
): Promise<string | null> {
  if (user === null) {
    return Promise.resolve(null);
  }

  return import('@invideoio/web-shared/lib/API/team-setting')
    .then(module => module.TeamSetting)
    .then(TeamSetting => TeamSetting.fetchInviteData(teamToken))
    .then(({ data, status }) => {
      if (status != 200 || !data || data.error_code) {
        throw new Error();
      } else {
        switch (data.data.intent) {
          case 'review': {
            const teamId = data.data.id;
            const role = data.data.role;
            const videoId = data?.data?.metadata?.video_id;

            //Check if user already exists in the team
            // and the role also matches
            if (
              user &&
              user.account === teamId &&
              user.role === role &&
              videoId != undefined
            ) {
              //Redirect to /review
              return fetchMasterJSON(videoId.toString()).then(
                (masterJSON: any) => {
                  const dimension = masterJSON.dimensions[0];
                  const templateId = masterJSON[dimension].template_id;
                  return `/review?id=${templateId}&type=story&dimension=${dimension}&video_id=${videoId}`;
                },
              );
            } else {
              return null;
            }
          }
          default: {
            return null;
          }
        }
      }
    });
}

const Home = (props: HomeProps) => {
  const { homepageState } = props;
  const globalState = useContext(StateContext);

  const { state } = useSharedState();
  const router = useRouter();
  const dispatch = useContext(DispatchContext);
  const { t, lang } = useTranslation('home');
  const { platform, os, browser, config, redirectionLink } = globalState;
  const [isCTAIntersected, setIsCTAIntersected] = useState(true);

  const appName = process.env.NEXT_PUBLIC_APP_NAME;

  useLazyload();

  useEffect(() => {
    const user = state.user;
    if (user && router.isReady) {
      const searchString = window.location.search;
      const searchParams =
        (searchString && qs.parse(searchString.slice(1))) || {};
      const action = router.query['action'] || searchParams['action'];
      if (
        typeof action === 'string' &&
        decodeURIComponent(action || '') === window.btoa('team-invite')
      ) {
        const queryParams = new URLSearchParams(window.location.search);
        const teamToken = queryParams.get('team-token');
        if (teamToken === null) {
          dispatch(openTeamSelectionModal());
        } else {
          getReviewRedirectURL(user, teamToken)
            .then(res => {
              if (res) {
                window.open(res, '_self');
              } else {
                dispatch(openTeamSelectionModal());
              }
            })
            .catch(() => {
              dispatch(openTeamSelectionModal());
            });
        }
      } else {
        window.open(
          generateRedirectionURLwithHost(
            RELATIVE_PATH.marketingTemplates,
            state.user,
          ),
          '_self',
        );
      }
    }
  }, [dispatch, router.isReady, router.query]);

  useEffect(() => {
    setCommonAnalyticsProperties();

    const AppName = process.env.NEXT_PUBLIC_APP_NAME;

    if (AppName === 'kizoa') {
      AnalyticsUtils.setReferrer(Referrer.KIZOA);
    } else if (
      platform === PlatformType.Mobile ||
      platform === PlatformType.Tablet
    ) {
      AnalyticsUtils.setReferrer(Referrer.WEBSITE);
    } else {
      AnalyticsUtils.setReferrer(Referrer.APP);
    }

    registerAndSendPageViewEvent(PageType.HOMEPAGE, {
      location: window.location.href,
    });

    sendAmplitudeAnalyticEvent(AmplitudeEvents.appLoad);
  }, []);

  if (!homepageState) {
    return null;
  }

  return (
    <ParallaxProvider>
      <AppLayout platform={platform}>
        <HomepageSEO seo={homepageState.SEO} locale={lang} />
        <FixedBanner browser={browser} />
        <HeaderWithCTA
          isCTAIntersected={isCTAIntersected}
          pageType={PageType.HOMEPAGE}
        />
        {appName === 'kizoa' && (
          <Banner
            bannerHeading={t('legacyBanner.primary_text')}
            ctaActionUrl={process.env.NEXT_PUBLIC_KIZOA_LEGACY_URL}
          />
        )}
        <HomePageV2 homepageState={homepageState} />
      </AppLayout>
    </ParallaxProvider>
  );
};

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  let homepageState = null;

  try {
    homepageState = await getHomepageV2('/homepage-v-2', ctx.locale);

    if (!!homepageState?.workflowTemplate?.workflowTemplateIds) {
      const ids = homepageState.workflowTemplate.workflowTemplateIds
        .split(',')
        .map(no => +no);
      homepageState.workflowTemplate.templates = await fetchTemplates(ids);
    }
  } catch (error) {
    console.log(error);
  }

  let maxage = 3600;

  if (process.env.NEXT_PUBLIC_ENV !== 'production') {
    maxage = 300; // 5mins
  }

  setCacheControlHeaders(ctx, maxage);

  return { props: { homepageState } };
}

export default Home;
